<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Registrar Ubicación y/o Imagen de Clientes</v-card-title>
        <DataTableClientLocation
          :rol="rolUserLogged"
         ></DataTableClientLocation>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableClientLocation from './datatables/DataTableClientLocation.vue'

export default {
  components: {

    DataTableClientLocation,

  },
  data: () => ({
    rolUserLogged: null,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },

}
</script>
