<template>
  <v-layout>
    <v-file-input
      v-model="objeto"
      name="image"
      accept="image/png, image/jpeg"
      small-chips
      outlined
      dense
      hide-details="true"
      show-size
      multiple
      clearable
      :label="etiqueta"
      prepend-icon=""
      :append-icon=" icons.mdiPaperclipPlus"
      @change="setImage"
    >
    </v-file-input>
    <!-- <template v-slot:selection="{ text, index, file }">
                    <v-chip small text-color="white" color="#295671" close @click:close="remove(index)">
                        {{ text }}
                    </v-chip>
                </template> -->

    <v-dialog
      v-model="dialog"
      :max-width="dialogMaxWidth"
      :max-height="dialogMaxHeight"
      hide-overlay
      :disabled="!imgSrc"
    >
      <!-- <img
        v-show="cropSrc"
        slot="activator"
        :src="cropSrc"
        style="width: 200px; border: 1px solid gray"
        alt="Cropped Image"
      /> -->
      <v-card>
        <v-card-title> <span class="headline">Herramienta de Recorte</span> </v-card-title>
        <v-card-text>
          <vue-cropper
            ref="cropper"
            :guides="false"
            :view-mode="0"
            drag-mode="move"
            :auto-crop-area="0.5"
            :min-container-width="250"
            :min-container-height="180"
            :background="true"
            :rotatable="true"
            :src="imgSrc"
            alt="Imagen"
            :modal="true"
            :img-style="{ width: '400px', height: '400px' }"
            :center="false"
            :highlight="true"
          />
        </v-card-text>
        <v-card-actions>
          <!-- <v-tooltip> -->
          <v-icon
            color="blue"
            @click="cropImage"
          >
            {{ icons.mdiCrop }}
          </v-icon>
          <v-spacer></v-spacer>
          <!-- </v-tooltip> -->
          <v-icon
            color="blue"
            @click="rotate('r');"
          >
            {{ icons.mdiCropRotate }}
          </v-icon>

          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            flat
            @click="cancel()"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import {
  mdiCrop, mdiAccountPlus, mdiCropRotate, mdiPaperclipPlus,
} from '@mdi/js'

// import axios from '@/axios.js'

export default {
  components: {
    VueCropper,
  },

  props: {
    value: {},
    pk: { default: 'image_key' },
    dialogMaxWidth: { default: '600px' },
    dialogMaxHeight: { default: '0.8vh' },
    maxWidth: { default: 1920 },
    maxHeight: { default: 1200 },
    etiqueta: {},

    // the URL of the blob image
    objectUrl: { default: '' },
  },
  data() {
    return {
      icons: {
        mdiCrop,
        mdiCropRotate,
        mdiAccountPlus,
        mdiPaperclipPlus,

      },

      objeto: {},
      imgSrc: '',
      cropImg: null,
      dialog: false,
      file: null,
      filename: null,
      cropBlob: null,
    }
  },
  computed: {
    croppedFile() {
      return new File([this.cropImg], this.file.name, { type: this.file.type })
    },
    cropSrc() {
      return this.cropImg || this.value
    },
  },
  methods: {

    setImage() {
      // const file = e;

      const file = this.objeto[0]

      // this.file = file
      this.filename = file.name

      if (!file.type.includes('image/')) {
        // eslint-disable-next-line no-alert
        alert('Por favor seleccione una imagen')

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.imgSrc = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
          this.$emit('updatedataUrl', this.imgSrc)
        }

        reader.readAsDataURL(file)
        this.dialog = true
      } else {
        // eslint-disable-next-line no-alert
        alert('Lo sentimos el archivo seleccionado no es compatible.')
      }
    },
    cancel() {
      this.objeto = {}
      this.dialog = false
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      // this.$emit('updateobjectUrl', this.cropImg)
      console.log(this.cropImg)
      console.log(this.$refs.cropper.getCroppedCanvas().toDataURL())
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
        })
        .toBlob(
          blob => {
            this.cropImg = URL.createObjectURL(blob)
            this.croppedBlob = blob
            console.log(this.cropImg)
            this.$emit('updateobjectUrl', this.cropImg)
            this.$emit('updateobjectUrl', this.croppedBlob)
          },
          'image/jpeg',
          0.95,
        )
      this.dialog = false
    },
    rotate(dir) {
      if (dir === 'r') {
        this.$refs.cropper.rotate(90)
      } else {
        this.$refs.cropper.rotate(-90)
      }
    },
    flip(vert) {
      const { scaleX, scaleY, rotate } = this.$refs.cropper.getData()

      // when image is rotated, direction must be flipped
      if (rotate === 90 || rotate === 270) {
        vert = !vert
      }
      if (vert) {
        this.$refs.cropper.scale(scaleX, -1 * scaleY)
      } else {
        this.$refs.cropper.scale(-1 * scaleX, scaleY)
      }
    },
  },
}
</script>
