<template>
  <v-container fluid>
    <!-- <v-btn color="warning" @click="generarReportePapu">Ver Reporte</v-btn> -->
    <v-data-table
      :headers="headers"
      :items="clientData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingClients"
      loading-text="Cargando Clientes..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por nombre / dni / email / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <!-- <template v-slot:[`item.imagebyte`]>
      <v-img :src="item.imagebyte" />
    </template> -->

      <template v-slot:item.imagen="{ item }">
        <v-avatar>
          <v-img
            :src="item.imagebyte"
            height="50"
            width="50"
            @click="openDialog(item.imagebyte)"
          ></v-img>
        </v-avatar>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="$refs.dialogRegisterImage.openDialog(item)"
            >
              {{ icons.mdiCamera }}
            </v-icon>
          </template>
          <span>Registrar Foto</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="warning"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="warning"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="$refs.dialogregisterLocation.openDialog(item)"
            >
              {{ icons.mdiMapMarkerPlusOutline }}
            </v-icon>
          </template>
          <span>Registrar Ubicación</span>
        </v-tooltip>
        <!-- <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado' || rol == 'Cobrador' ) && $route.name !== 'clientsassigned'"
              color="info"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="openDialog(item.imagebyte)"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>Ver Foto</span>
        </v-tooltip> -->
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      max-width="430px"
    >
      <v-img
        :src="imagenSeleccionada"
        contain
      />
      <v-btn @click="dialog = false">
        Cerrar
      </v-btn>
    </v-dialog>
    <dialogregisterLocation
      ref="dialogregisterLocation"
      @assigned="getClientListOfEmployee"
    ></dialogregisterLocation>
    <dialogRegisterImage
      ref="dialogRegisterImage"
      @recargar="checkEmployeeOrClient"
    ></dialogRegisterImage>
  </v-container>
</template>

<script>
import {
  mdiMapMarkerPlusOutline, mdiCamera, mdiEyeOutline,
} from '@mdi/js'
import axios from 'axios'

import dialogregisterLocation from '../AssignLocationDialog.vue'
import dialogRegisterImage from '../AssignImageDialog.vue'

export default {
  components: { dialogregisterLocation, dialogRegisterImage },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data: () => ({
    dialog: false,
    imagenSeleccionada: '',
    icons: {
      mdiMapMarkerPlusOutline,
      mdiCamera,
      mdiEyeOutline,
    },
    headers: [

      { text: 'Cliente', value: 'completeClientName' },
      { text: 'Dirección', value: 'simpleCommercialAddress' },
      { text: 'Telefono', value: 'phone' },
      { text: 'Imagen', value: 'imagen' },

      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    clientData: [],
    buscar: '',
    clienteAEliminar: null,
    loadingClients: false,
    mensajeSnack: '',
    employeePersonId: null,
    dialogLocation: false,
    clienteADesasignar: null,
  }),

  mounted() {
    // if (this.$route.params.id || this.rol === 'Cobrador') { this.getClientListOfEmployee() } else { this.getClientList() }
    this.checkEmployeeOrClient()

    // this.clientData.forEach(item => {
    //   const imagenData = new Uint8Array(item.imagebyte)
    //   this.imagenUrl = `data:image/png;base64,${btoa(String.fromCharCode.apply(null, imagenData))}`
    // })
  },
  methods: {
    // convertirBytesAImagenBase64(bytes) {
    //   this.imageBinaryData = bytes

    //   // Convertir la imagen a una cadena base64 con formato data URI
    //   const blob = new Blob([this.imageBinaryData], { type: 'image/png' })
    //   const reader = new FileReader()
    //   reader.onload = () => {
    //     const dataURI = `data:image/png;base64,${window.btoa(reader.result)}`
    //     this.imagenSeleccionada = dataURI
    //   }
    //   reader.readAsDataURL(blob)
    // },
    checkEmployeeOrClient() {
      if (this.$route.params.id || this.rol === 'Cobrador') { this.getClientListOfEmployee() } else { this.getClientList() }
    },
    openDialog(imageUrl) {
      this.imagenSeleccionada = imageUrl
      this.dialog = true
    },
    dialogLocationItem(item) {
      this.clienteADesasignar = item
      this.dialogLocation = true
    },
    Locationconfirm() {
      this.DesasignarClienteConfirmado()
    },
    closeDialogLocation() {
      this.dialogLocation = false
      this.clienteADesasignar = null
    },

    async getClientList() {
      this.url = `${this.$store.getters.urlBase}/api/Client`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        params: { clientState: this.stateClient },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.clientData = respuesta.data
        this.loadingClients = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
    async getClientListOfEmployee() {
      this.url = `${this.$store.getters.urlBase}/api/Client/ListByEmployee`
      const employeeId = this.$route.params.id ? this.$route.params.id : parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { employeePersonID: employeeId, clientState: this.stateClient },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.clientData = respuesta.data

        this.loadingClients = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
  },
}
</script>
