<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <!-- <v-row v-if="this.$store.getters.usuario.user_rol === 'Encargado'">
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="es"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <Crop-image-vuetify
                  etiqueta="Foto"
                  @updateobjectUrl="imagenSeleccion"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="isSaving"
          depressed
          @click=" RegisterImage()"
        >
          Registrar Imagen
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import {
  mdiMapMarkerRadiusOutline,
} from '@mdi/js'
import CropImageVuetify from '../../components/CropImageVuetify'

export default {
  components: { CropImageVuetify },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data() {
    return {
      dialog: false,
      autoUpdate: true,
      latitud: '',
      longitud: '',
      imageData: '',
      icons: {

        mdiMapMarkerRadiusOutline,

      },
      loadingButton: false,
      mensajeSnack: '',
      isSaving: false,

    }
  },

  computed: {
    formTitle() {
      return 'Asignar Imagen'
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },

  },
  mounted() {

  },
  methods: {

    openDialog(client) {
      this.client = client
      console.log(this.client)
      this.dialog = true
    },
    imagenSeleccion(e) {
      this.imageData = e
    },
    closeDialog() {
      this.imageData = null
      this.$refs.form.reset()
      this.dialog = false
    },

    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('recargar')
    },

    async RegisterImage() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Client/UpdateCommercialImage`

        const formData = new FormData()
        formData.append('personID', this.client.id)
        formData.append('imageData', this.imageData)

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
          body: formData,
        }
        await axios
          .post(this.url, formData, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.$refs.form.reset()
              this.$store.dispatch('showSnack', {
                text: 'Operacion exitosa.',
                color: 'success',
                timeout: 2500,
              })
              this.isSaving = false
              
              this.$emit('recargar')
              this.closeDialog()
            },
          )
          .catch(e => {
            if (e.response) {
              if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },

  },
}
</script>
