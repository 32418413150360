var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loadingClients,"loading-text":"Cargando Clientes...","no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por nombre / dni / email / etc.","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.imagen",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":item.imagebyte,"height":"50","width":"50"},on:{"click":function($event){return _vm.openDialog(item.imagebyte)}}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"success","size":"18"},on:{"click":function($event){return _vm.$refs.dialogRegisterImage.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCamera)+" ")])]}}],null,true)},[_c('span',[_vm._v("Registrar Foto")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-3",attrs:{"color":"warning","size":"18"},on:{"click":function($event){return _vm.$refs.dialogregisterLocation.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiMapMarkerPlusOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Registrar Ubicación")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"430px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-img',{attrs:{"src":_vm.imagenSeleccionada,"contain":""}}),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1),_c('dialogregisterLocation',{ref:"dialogregisterLocation",on:{"assigned":_vm.getClientListOfEmployee}}),_c('dialogRegisterImage',{ref:"dialogRegisterImage",on:{"recargar":_vm.checkEmployeeOrClient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }