<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <!-- <v-row v-if="this.$store.getters.usuario.user_rol === 'Encargado'">
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="es"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="latitud"
                  persistent-hint
                  :prepend-icon="icons.mdiMapMarkerRadiusOutline"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="longitud"
                  persistent-hint
                  :prepend-icon="icons.mdiMapMarkerRadiusOutline"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="isSaving"
          depressed
          @click=" RegisterLocation()"
        >
          Registrar Ubicación Actual
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiMapMarkerRadiusOutline,
} from '@mdi/js'

export default {
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data() {
    return {

      dialog: false,
      autoUpdate: true,
      latitud: '',
      longitud: '',
      icons: {

        mdiMapMarkerRadiusOutline,

      },
      loadingButton: false,
      mensajeSnack: '',
      isSaving: false,

    }
  },
  computed: {
    formTitle() {
      return 'Asignar Ubicación Actual'
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },

  },
  mounted() {
    this.getLocationData()
  },
  methods: {

    openDialog(client) {
      this.client = client

      this.dialog = true
    },
    async getLocationData() {
      if (!('geolocation' in navigator)) {
        this.latitud = '0'
        this.longitud = '0'

        return alert(
          'Tu navegador no soporta el acceso a la ubicación. Intenta con otro',
        )
      }

      const onUbicacionConcedida = loc => {
        const location = loc.coords

        this.latitud = `${location.latitude}`
        this.longitud = `${location.longitude}`
      }
      const onErrorDeUbicacion = err => {
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log('Error obteniendo ubicación: ', err)
        this.latitud = '0'
        this.longitud = '0'
      }

      const optionRequest = {
        enableHighAccuracy: true, // Alta precisión
        maximumAge: 0, // No queremos caché
        timeout: 5000, // Esperar 5 segundos
      }

      navigator.geolocation.getCurrentPosition(
        onUbicacionConcedida,
        onErrorDeUbicacion,
        optionRequest,
      )
    },

    closeDialog() {
      this.dialog = false
    },

    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    async RegisterLocation() {
      this.isSaving = true
      this.url = `${this.$store.getters.urlBase}/api/Client/UpdateGPSLocation`

      const params = {
        // eslint-disable-next-line radix
        PersonID: parseFloat(this.client.id),
        Latitude: this.latitud,
        Longitude: this.longitud,

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.$store.dispatch('showSnack', {
              text: 'Localización Asignada Correctamente',
              color: 'success',
              timeout: 2500,
            })
            this.$router.push({ name: 'listciientlocationimg' })
            this.isSaving = false
            this.dialog = false
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
            this.isSaving = false
          } else {
            this.mensajeSnack = 'Error de conexion'
            this.isSaving = false
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
          this.isSaving = false
        })
    },

  },
}
</script>
